import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import getTypeStyle from '@/utils/getTypeStyle'
import * as UI from '@/components/UI'

export const Section = styled.section`
  position: relative;
  overflow: hidden;
  background: red;
  margin-top: -8rem;
  padding: 8rem 0 5rem;
  background: linear-gradient(180deg, #FDFDFE 0%, #FAFBFC 100%);
  ${above('sm')`
  margin-top: -10rem;
  padding: 10rem 0 6rem;
  `}
  ${above('md')`
    margin-top: -12rem;
    padding: 12rem 0 8rem;
  `}
  ${above('lg')`
    margin-top: -16rem;
    padding: 16rem 0 10rem;
  `}
  ${above('lg')`
    margin-top: -22rem;
    padding: 22rem 0 12rem;
  `}
`

export const TextWrapper = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 1.25rem;
  ${above('sm')`
    width: ${getColumnSize(10)};
  `}
  ${above('md')`
    margin-bottom: 2.5rem;
    width: ${getColumnSize(8)};
  `}
  ${above('lg')`
    width: ${getColumnSize(6)};
  `}
  ${above('xg')`
    width: ${getColumnSize(5)};
  `}
`

export const TestimonialsWrapper = styled.div`
  margin: 4rem 0 7rem;
  
  .slick-list {
    overflow: hidden;
  } 
  ${above('sm')`
    margin: 4rem ${getColumnSize(1)} 7rem;
    width: ${getColumnSize(10)};
  `} 
  ${above('md')`
    .slick-list {
      overflow: visible;
    } 
    margin: 6rem ${getColumnSize(2)} 8rem;
    width: ${getColumnSize(8)};
  `} 
  ${above('lg')`
    margin: 8rem ${getColumnSize(3)} 10rem;
    width: ${getColumnSize(6)};
  `} 
  ${above('xg')`
    margin: 10rem ${getColumnSize(4)} 12rem ${getColumnSize(3)};
    width: ${getColumnSize(5)};
  `};
`

export const List = styled(UI.Grid).attrs({
  as: 'ul',
  'vertical-gutter': true,
})``

export const Item = styled.li`
  width: ${getColumnSize(6)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1.5rem 0;
  ${above('lg')`
    width: ${getColumnSize(3)};
  `}
`

export const ItemImage = styled(UI.Image)`
  width: 2.75rem;
  height: 2.75rem;
  margin-bottom: 0.5rem;
  ${above('md')`
    width: 3rem;
    height: 3rem;
  `}
  ${above('lg')`
    width: 3.5rem;
    height: 3.5rem;
    margin-bottom: 0.75rem;
  `}
  ${above('xg')`
    width: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
  `}
`

export const ItemTitle = styled.div`
  ${getTypeStyle('blogText')};
  max-width: 9em;
  font-family: ${p => p.theme.type.fonts.headline};
  ${above('md')`
    ${getTypeStyle('caption')};
    max-width: 8em;
  `}
`
