import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Layout from '@/layout'
import NavBar from '@/components/Partials/NavBar/NavBar'
import Footer from '@/components/Partials/Footer/Footer'
import Hero from '@/components/contents/SeriousGamesIndex/Hero'
import Featured from '@/components/contents/SeriousGamesIndex/Featured'
import Customize from '@/components/contents/SeriousGamesIndex/Customize'
import Experience from '@/components/contents/SeriousGamesIndex/Experience'
import Test from '@/components/contents/SeriousGamesIndex/Test'
import Past from '@/components/contents/SeriousGamesIndex/Past'
import Future from '@/components/contents/SeriousGamesIndex/Future'
import Contact from '@/components/contents/shared/Contact/Contact'
import { ContactWrapper } from '@/components/contents/ContactIndex/Contact.css'
import Prefooter from '@/components/Partials/Prefooter/Prefooter'
import * as UI from '@/components/UI'
import srcHeart from '@/assets/images/heart-red.png'
import srcCheeto from '@/assets/images/nobox-yellow.png'
import srcCloud from '@/assets/images/cloud-blue.png'
import getMetaImage from '@/utils/getMetaImage'

const StyledContact = styled(Contact)`
  background: ${p => p.theme.colors.white};
`

const SeriousGamesIndexPage = ({ data }) => {
  const content = { ...data?.content?.data, slug: data?.content?.slug }

  const metaImage = getMetaImage(content)

  return (
    <Layout
      title={content.meta_title}
      description={content.meta_description}
      image={metaImage}
    >
      <NavBar />
      <Hero content={content} />
      <Featured content={content} />
      <Customize content={content} />
      <Experience content={content} />
      <Test content={content} />
      <Past content={content} />
      <Future content={content} />
      <ContactWrapper>
        <UI.Parallax top='30%' left='-200px' force={1.3} offsetComp={30}>
          {offSet => (
            <UI.ImageLoose
              style={{
                transform: `translateY(${offSet}px) translateZ(0)`,
              }}
              src={srcCloud}
              alt={content.meta_title}
              width='400'
              hiddenMobile
            />
          )}
        </UI.Parallax>
        <UI.Parallax top='0%' right='15%' force={1.1} offsetComp={10}>
          {offSet => (
            <UI.ImageLoose
              style={{
                transform: `translateY(${offSet}px) translateZ(0) rotate(245deg)`,
              }}
              src={srcCheeto}
              alt={content.meta_title}
              width='400'
              hiddenMobile
            />
          )}
        </UI.Parallax>
        <UI.Parallax bottom='150px' right='180px' force={1.4} offsetComp={20}>
          {offSet => (
            <UI.ImageLoose
              style={{
                transform: `translateY(${offSet}px) translateZ(0)`,
              }}
              src={srcHeart}
              alt={content.meta_title}
              width='320'
            />
          )}
        </UI.Parallax>
        <StyledContact content={content} />
      </ContactWrapper>
      <Prefooter content={content} />
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query SeriousGameIndex($locale: String) {
    content: prismicSeriousGamesPage(lang: { eq: $locale }) {
      lang
      slug: uid
      data {
        prefooter_cards {
          card {
            document {
              ... on PrismicPrefootercard {
                data {
                  title
                  description
                  type {
                    uid
                    type
                    url
                    slug
                  }
                }
              }
            }
          }
        }
        meta_title
        meta_description
        meta_image {
          localFile {
            childImageSharp {
              fixed(width: 1200, height: 630) {
                src
              }
            }
          }
        }
        hero_title
        hero_description
        featured {
          serious_game {
            document {
              ... on PrismicSeriousGame {
                slug: uid
                data {
                  meta_title
                  meta_description
                  meta_image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          aspectRatio
                          src
                          sizes
                          originalName
                          srcSet
                        }
                      }
                    }
                  }
                  tags {
                    tag
                  }
                }
              }
            }
          }
        }
        customize_title
        customize_description
        customize_list {
          item
        }
        customize_card_title
        customize_card_link
        customize_card_description
        experience_title
        experience_description
        experience_image {
          alt
          localFile {
            childImageSharp {
              fluid {
                aspectRatio
                src
                sizes
                originalName
                srcSet
              }
            }
          }
        }
        experience_steps {
          image {
            alt
            localFile {
              childImageSharp {
                fluid {
                  aspectRatio
                  src
                  sizes
                  originalName
                  srcSet
                }
              }
            }
          }
          title
        }
        experience_list_title
        experience_list {
          item
        }
        experience_image_list {
          experience_list_item {
            localFile {
              childImageSharp {
                fluid {
                  aspectRatio
                  src
                  sizes
                  originalName
                  srcSet
                }
              }
            }
            alt
          }
        }
        test_title
        test_description
        test_list {
          title
          description
        }
        past_title
        past_description
        past_video
        past_image {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 2560, quality: 40) {
                aspectRatio
                src
                sizes
                originalName
                srcSet
              }
            }
          }
        }
        testimonials {
          testimonial {
            document {
              ... on PrismicTestimonial {
                data {
                  name
                  position
                  interview_text
                  interview_link
                  content {
                    html
                  }
                  image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          aspectRatio
                          originalName
                          sizes
                          src
                          srcSet
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        future_title
        future_description
        future_list {
          title
          image {
            alt
            localFile {
              childImageSharp {
                fluid {
                  aspectRatio
                  originalName
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
    }
  }
`

export default SeriousGamesIndexPage
