import React from 'react'
import * as UI from '@/components/UI'
import srcHeart from '@/assets/images/heart-blue.png'
import {
  Section,
  Grid,
  TextCell,
  List,
  Item,
  CardCell,
  Card,
  CardInner,
  CardTitle,
} from './Customize.css'

const SeriousGamesIndexCustomize = ({ content }) => {
  return (
    <Section>
      <UI.Container>
        <Grid>
          <UI.Parallax top='-160px' right='20%' force={1.4} offsetComp={10}>
            {offSet => (
              <UI.ImageLoose
                style={{
                  transform: `translateY(${offSet}px) translateZ(0)`,
                }}
                src={srcHeart}
                alt={content.customize_title}
                width='380'
                hiddenMobile
              />
            )}
          </UI.Parallax>
          <TextCell>
            <UI.SectionTitle>{content.customize_title}</UI.SectionTitle>
            <UI.SectionDescription>
              {content.customize_description}
            </UI.SectionDescription>
            <List>
              {content.customize_list.map(({ item }) => (
                <Item key={item}>{item}</Item>
              ))}
            </List>
          </TextCell>
          <CardCell>
            <Card
              color='blue'
              fixColorInMobile
              to={content.customize_card_link}
            >
              <CardInner>
                <div>
                  <CardTitle>{content.customize_card_title}</CardTitle>
                  <UI.SmallText>
                    {content.customize_card_description}
                  </UI.SmallText>
                </div>
                <UI.Icon.Plus size={32} />
              </CardInner>
            </Card>
          </CardCell>
        </Grid>
      </UI.Container>
    </Section>
  )
}

export default SeriousGamesIndexCustomize
