import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import * as UI from '@/components/UI'

export const Section = styled.section`
  padding: 0 0 4.25rem;
  background: ${p => p.theme.colors.white};
  ${above('md')`
    padding: 6rem 0 8rem;
  `}
`

export const TextWrapper = styled.div`
  ${above('sm')`
    width: ${getColumnSize(10)};
  `}
  ${above('md')`
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: ${getColumnSize(8)};
  `}
  ${above('lg')`
    width: ${getColumnSize(6)};
  `}
`

export const ListWrapper = styled.div`
  position: relative;
  ${above('md')`
    padding: 5rem 0;
  `}
`

export const List = styled(UI.Grid).attrs({
  as: 'ol',
  'vertical-gutter': true,
})`
  position: relative;
`

export const Item = styled.li`
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  position: relative;

  ${above('md')`
    margin-top: 0;
    margin-bottom: 0;
    margin-left: ${getColumnSize(0)};
    width: ${getColumnSize(6)};
    margin-right: ${getColumnSize(6)};
    display: flex;
    flex-direction: column;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 0px;
      height: 100%;
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      &:before {
        height: calc(100% + 24px);
      }
    }

    &:nth-child(3) {
      &:before {
        height: calc(100% - 24px);
        top: 24px;
      }
    }

    &:after {
      content: '';
      display: block;
      height: 10px;
      width: 10px;
      position: absolute;
      background: white;
      border-radius: 100%;
      border: 1px solid ${p => p.theme.colors.blue};
      top: 24px;
    }

    &:nth-child(odd) {
      &:before {
        right: 0;
        left: auto;
        border-right: 1px solid ${p => p.theme.colors.blue};
      }
      &:after {
        right: -6px;
      }
    }
    &:nth-child(even) {
      align-items: flex-start;

      &:before {
        border-left: 1px solid ${p => p.theme.colors.blue};
        left: -1px;
      }
      &:after {
        left: -6px;
      }
    }

    &:first-child {
      &:before {
        border-right: 1px dashed ${p => p.theme.colors.blue};
      }
    } 
    &:nth-child(2) {
      &:before {
        border-left: 1px dashed ${p => p.theme.colors.blue};
      }
    }
    &:nth-child(2n) {
      margin-left: ${getColumnSize(6)};
      width: ${getColumnSize(6)};
      margin-right: ${getColumnSize(0)};
    }
  `}
  ${above('lg')`
    margin-left: ${getColumnSize(1)};
    width: ${getColumnSize(5)};
    margin-right: ${getColumnSize(6)};
    &:nth-child(2n) {
      margin-left: ${getColumnSize(6)};
      width: ${getColumnSize(5)};
      margin-right: ${getColumnSize(1)};
    }
    &:nth-child(even) {
      align-items: flex-end;
    }
  `}
  ${above('xg')`
    margin-left: ${getColumnSize(2)};
    width: ${getColumnSize(4)};
    margin-right: ${getColumnSize(6)};
    &:nth-child(2n) {
      margin-left: ${getColumnSize(6)};
      width: ${getColumnSize(4)};
      margin-right: ${getColumnSize(2)};
    }
  `}
`

export const ItemTop = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;

  ${above('lg')`
    width: 75%;
  `}
`

export const ItemIndex = styled(UI.Caption)`
  font-family: ${p => p.theme.type.fonts.headline};
  color: ${p => p.theme.colors.gray};
  min-width: 1.75rem;
  user-select: none;

  ${above('lg')`
    margin-right: 0.5rem;
    min-width: 0;
    position: absolute;
    right: 100%;
  `}
`

export const ItemTitle = styled(UI.Caption)`
  font-family: ${p => p.theme.type.fonts.headline};
  margin-bottom: 0.75rem;
  display: flex;
`

export const ItemDescription = styled(UI.SmallText)`
  margin-left: 1.75rem;
  color: ${p => p.theme.colors.gray};

  ${above('md')`
    margin-left: 0;
  `}

  ${above('lg')`
    margin-left: 0;
    width: 75%;
  `}
`
