import React from 'react'
import * as UI from '@/components/UI'
import srcHeart from '@/assets/images/heart-blue.png'
import { Section, Container, TextWrapper, ImageWrapper, Play } from './Past.css'

const SeriousGamesIndexPast = ({ content }) => {
  return (
    <Section>
      <UI.Parallax top='5%' left='-120px' force={1.1} offsetComp={10}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0) rotate(180deg)`,
            }}
            src={srcHeart}
            alt={content.past_title}
            width='340'
            hiddenMobile
          />
        )}
      </UI.Parallax>
      <Container>
        <UI.Grid>
          <TextWrapper>
            <UI.SectionTitle>{content.past_title}</UI.SectionTitle>
            <UI.SectionDescription>
              {content.past_description}
            </UI.SectionDescription>
          </TextWrapper>
        </UI.Grid>
        {content.past_video && content.past_image && (
          <UI.Modal
            handler={
              <ImageWrapper>
                <UI.Image src={content.past_image} />
                <Play />
              </ImageWrapper>
            }
          >
            <UI.Youtube id={content.past_video} />
          </UI.Modal>
        )}
      </Container>
    </Section>
  )
}

export default SeriousGamesIndexPast
