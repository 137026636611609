import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import getTypeStyle from '@/utils/getTypeStyle'
import * as UI from '@/components/UI'

export const Section = styled.section`
  margin: 1.5rem 0 4rem;
  ${above('sm')`
    margin: 2rem 0 4rem;
  `}
  ${above('md')`
    margin: 4rem 0 8.5rem;
  `}
  ${above('lg')`
    margin: 6.25rem 0 10.25rem;
  `}
`

export const Grid = styled(UI.Grid)`
  justify-content: center;
  align-items: stretch;
`

export const TextCell = styled.div`
  width: ${getColumnSize(12)};
  margin-bottom: 4rem;
  ${above('md')`
    margin-top: 4rem;
    margin-bottom: 5rem;
    width: ${getColumnSize(6)};
  `}
  ${above('lg')`
    margin-right: ${getColumnSize(1)};
    width: ${getColumnSize(5)};
  `}
`

export const List = styled.ul`
  margin: 2.25rem 0;
  ${above('md')`
    margin: 3.25rem 0;
  `}
`

export const Item = styled.li`
  margin-bottom: 1rem;
  ${getTypeStyle('smallText')};
  display: flex;
  position: relative;
  &:before {
    content: '—';
    color: ${p => p.theme.colors.blue};
    margin-right: 0.5rem;
    ${above('lg')`
      position: absolute;
      right: 100%;
    `}
  }
`

export const CardCell = styled.div`
  position: relative;
  z-index: 20;
  width: ${getColumnSize(12)};
  ${above('md')`
     width: ${getColumnSize(6)};
  `}
  ${above('lg')`
     width: ${getColumnSize(5)};
  `}
  ${above('lg')`
     width: ${getColumnSize(4)};
  `}
`

export const Card = styled(UI.Card)``

export const CardInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 15rem;
  height: 100%;
`

export const CardTitle = styled.h4`
  margin-bottom: 1rem;
  font-family: ${p => p.theme.type.fonts.headline};
  font-weight: 500;
  max-width: 7em;
  ${getTypeStyle('subtitle')};
  ${above('md')`
    margin-bottom: 1.5rem;
  `}
`
