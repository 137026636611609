import React from 'react'
import * as UI from '@/components/UI'

import srcMoon from '@/assets/images/circle-blue.png'
import srcCheeto from '@/assets/images/cheetos-blue.png'
import { Section, Grid, Cell } from './Hero.css'

const SeriousGamesIndexHero = ({ content }) => {
  return (
    <Section>
      <UI.Parallax top='10%' right='20%' force={1.1} offsetComp={50}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0)`,
            }}
            src={srcCheeto}
            alt={content.hero_title}
            width='380'
            hiddenMobile
          />
        )}
      </UI.Parallax>
      <UI.Parallax bottom='0' left='-120px' force={1.3} offsetComp={30}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0)`,
            }}
            src={srcMoon}
            alt={content.hero_title}
            width='300'
          />
        )}
      </UI.Parallax>
      <UI.Container>
        <Grid>
          <Cell>
            <UI.PageTitle>{content.hero_title}</UI.PageTitle>
            <UI.SectionDescription>
              {content.hero_description}
            </UI.SectionDescription>
          </Cell>
        </Grid>
      </UI.Container>
    </Section>
  )
}

export default SeriousGamesIndexHero
