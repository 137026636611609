import React from 'react'
import flatMap from '@/utils/flatMap'
import * as UI from '@/components/UI'
import { Section, Grid, Cell } from './Featured.css'

const SeriousGamesIndexFeatured = ({ content }) => {
  const featured =
    flatMap(content?.featured, x => x?.serious_game?.document)?.map(x => ({
      ...x?.data,
      slug: x?.slug,
    })) || []

  return (
    <Section>
      <UI.Container>
        <Grid style={{ rowGap: '32px' }}>
          {featured.map(x => (
            <Cell key={x.slug}>
              <UI.Card
                to={`/${content.slug}/${x.slug}`}
                color='blue'
                title={x.meta_title}
                description={x.meta_description}
                image={x?.meta_image}
                tags={x.tags}
              />
            </Cell>
          ))}
        </Grid>
      </UI.Container>
    </Section>
  )
}

export default SeriousGamesIndexFeatured
