import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import * as UI from '@/components/UI'

export const Section = styled.section`
  margin: 1.5rem 0 8rem;
  position: relative;

  ${above('sm')`
    margin: 2rem 0 8rem;
  `}
  ${above('md')`
    margin: 4rem 0 6rem;
  `}
  ${above('lg')`
    margin: 6.25rem 0 6rem;
  `}
`

export const Grid = styled(UI.Grid)`
  justify-content: center;
`

export const Cell = styled.div`
  width: ${getColumnSize(12)};
  ${above('md')`
     width: ${getColumnSize(10)};
   `}
  ${above('lg')`
     width: ${getColumnSize(8)};
   `}

   & * {
    width: 100%;
    max-width: 100%;
  }
`
