import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import * as UI from '@/components/UI'

export const Section = styled.section`
  padding-top: 4rem;
  background: ${p => p.theme.colors.white};
  position: relative;
  ${above('lg')`
    padding: 4rem 0 8rem;
  `}
  ${above('xg')`
    padding: 6.25rem 0 10rem;
  `}
`

export const Grid = styled(UI.Grid)`
  flex-direction: row-reverse;
`

export const ImageCell = styled.div`
  width: ${getColumnSize(12)};
  ${above('md')`
    width: ${getColumnSize(6)};
  `}
  ${above('lg')`
    width: ${getColumnSize(5)};
    margin-right: ${getColumnSize(1)};
  `}
`

export const ImageWrapper = styled.div`
  z-index: 20;
  position: relative;
  grid-template-columns: 50% 50% !important;
  grid-gap: 19px;
  margin: 3rem -1rem 1rem;

  ${above('md')`
    margin: 0;
    margin-left: ${getColumnSize(0)};
  `}
  ${above('lg')`
    margin-left: -${getColumnSize(2)};
    height: 0;
    bottom: 0;
    grid-gap: 39px!important;
  `}
`

export const ContentCell = styled.div`
  width: ${getColumnSize(12)};
  ${above('md')`
    width: ${getColumnSize(6)};
  `}
  ${above('xg')`
    width: ${getColumnSize(5)};
    margin-right: ${getColumnSize(1)};
  `}
`

export const Steps = styled(UI.Grid).attrs({ as: 'ol' })`
  position: relative;
  z-index: 20;
  padding-bottom: 4rem;
`

export const Step = styled.li`
  display: flex;
  align-items: center;
  width: ${getColumnSize(12)};
  font-family: ${p => p.theme.type.fonts.headline};
  color: ${p => p.theme.colors.maastricht}CC;
  ${above('lg')`
    display: block;
    width: ${getColumnSize(4)};
  `}
`

export const StepImage = styled(UI.Image)`
  width: 48px;
  height: 48px;
  object-fit: contain;
  margin: 0.25rem 0;
`

export const ListTitle = styled(UI.Label)`
  color: ${p => p.theme.colors.gray};
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-weight: 500;
`

export const List = styled.ul``

export const Item = styled(UI.SmallText).attrs({ as: 'li' })`
  margin-bottom: 1rem;
  display: flex;
  position: relative;
  &:before {
    content: '—';
    color: ${p => p.theme.colors.blue};
    margin-right: 0.5rem;
    ${above('lg')`
      position: absolute;
      right: 100%;
    `}
  }
`
