import React from 'react'
import Snuggle from 'react-snuggle'
import * as UI from '@/components/UI'

import srcMoon from '@/assets/images/circle-blue.png'
import srcCheeto from '@/assets/images/cheetos-blue.png'
import {
  Section,
  Grid,
  ImageCell,
  ImageWrapper,
  ContentCell,
  Steps,
  Step,
  StepImage,
  ListTitle,
  List,
  Item,
} from './Experience.css'

const SeriousGamesIndexExperience = ({ content }) => {
  return (
    <Section>
      <UI.Parallax top='30%' right='10%' force={1.2} offsetComp={20}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0)`,
            }}
            src={srcMoon}
            alt={content.experience_title}
            width='310'
            hiddenMobile
          />
        )}
      </UI.Parallax>
      <UI.Parallax bottom='10%' left='-140px' force={1.4} offsetComp={10}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0) rotate(90deg)`,
            }}
            src={srcCheeto}
            alt={content.experience_title}
            width='380'
            hiddenMobile
          />
        )}
      </UI.Parallax>
      <UI.Container>
        <Grid>
          <ContentCell>
            <UI.SectionTitle>{content.experience_title}</UI.SectionTitle>
            <UI.SectionDescription>
              {content.experience_description}
            </UI.SectionDescription>
            <Steps>
              {content.experience_steps.map(({ title, image }) => (
                <Step key={title}>
                  <StepImage src={image} />
                  <UI.BlogText>{title}</UI.BlogText>
                </Step>
              ))}
            </Steps>
            <ListTitle>{content.experience_list_title}</ListTitle>
            <List>
              {content.experience_list.map(({ item }) => (
                <Item key={item}>{item}</Item>
              ))}
            </List>
          </ContentCell>
          <ImageCell>
            <Snuggle container={<ImageWrapper />} rowGap='39'>
              {content.experience_image_list.map(image => {
                return <UI.Image key={image} src={image.experience_list_item} />
              })}
            </Snuggle>
          </ImageCell>
        </Grid>
      </UI.Container>
    </Section>
  )
}

export default SeriousGamesIndexExperience
