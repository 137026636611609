import React from 'react'
import flatMap from '@/utils/flatMap'
import * as UI from '@/components/UI'

import srcMoon from '@/assets/images/circle-blue.png'
import srcCloud from '@/assets/images/cloud-blue.png'
import {
  Section,
  TestimonialsWrapper,
  TextWrapper,
  List,
  Item,
  ItemImage,
  ItemTitle,
} from './Future.css'

const SeriousGamesIndexFuture = ({ content }) => {
  const testimonials =
    flatMap(content?.testimonials, x => x?.testimonial?.document)?.map(
      x => x?.data,
    ) || []
  return (
    <Section>
      <UI.Parallax top='90px' right='18%' force={1.2} offsetComp={10}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0)`,
            }}
            src={srcCloud}
            alt={content.future_title}
            width='400'
            hiddenMobile
          />
        )}
      </UI.Parallax>
      <UI.Parallax bottom='60%' left='-155px' force={1.1} offsetComp={15}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0)`,
            }}
            src={srcMoon}
            alt={content.future_title}
            width='310'
            hiddenMobile
          />
        )}
      </UI.Parallax>
      <UI.Container>
        <TestimonialsWrapper>
          <UI.TestimonialGroup testimonials={testimonials} color='blue' />
        </TestimonialsWrapper>
        <TextWrapper>
          <UI.SectionTitle>{content.future_title}</UI.SectionTitle>
          <UI.SectionDescription>
            {content.future_description}
          </UI.SectionDescription>
        </TextWrapper>
        <List>
          {content.future_list.map(({ title, image }) => (
            <Item key={title}>
              <ItemImage src={image} />
              <ItemTitle>{title}</ItemTitle>
            </Item>
          ))}
        </List>
      </UI.Container>
    </Section>
  )
}

export default SeriousGamesIndexFuture
