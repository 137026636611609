import React from 'react'
import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import * as UI from '@/components/UI'

export const Section = styled.section`
  background: ${p => p.theme.colors.white};
  position: relative;
  overflow: hidden;
  ${above('md')`
    padding-top: 3rem;
  `}
`

export const TextWrapper = styled.div`
  ${above('sm')`
    width: ${getColumnSize(10)};
  `}
  ${above('md')`
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: ${getColumnSize(8)};
  `}
  ${above('lg')`
    width: ${getColumnSize(6)};
  `}
`

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 0.125rem;
  overflow: hidden;
  margin-top: 0.5rem;
  cursor: pointer;
  ${above('md')`
    margin-top: 3rem;
  `}

  .gatsby-image-wrapper {
    width: 100%;
  }

  &[aria-expanded='true'] {
    opacity: 0;
  }

  &[aria-expanded='true'] > * {
    pointer-events: none;
  }
`

export const Play = styled.button.attrs({
  children: (
    <UI.Icon.PlayCircle
      color='transparent'
      background='white'
      backgroundOpacity={1}
    />
  ),
})`
  position: absolute;
  width: auto;
  display: block;
  color: ${p => p.theme.colors.white};
  box-shadow: 0 0.25rem 2.5rem 0 rgba(6, 29, 51, 0.2);
  border-radius: 50%;
  svg {
    width: 2.5rem;
    height: 2.5rem;
    ${above('md')`
    width: 4rem;
    height: 4rem;
    `}
  }
`

export const Container = styled(UI.Container)`
  position: relative;
  z-index: 20;
`
