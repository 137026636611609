import React from 'react'
import * as UI from '@/components/UI'
import {
  Section,
  TextWrapper,
  ListWrapper,
  List,
  Item,
  ItemTop,
  ItemIndex,
  ItemTitle,
  ItemDescription,
} from './Test.css'

const SeriousGamesIndexTest = ({ content }) => {
  return (
    <Section>
      <UI.Container>
        <TextWrapper>
          <UI.SectionTitle>{content.test_title}</UI.SectionTitle>
          <UI.SectionDescription>
            {content.test_description}
          </UI.SectionDescription>
        </TextWrapper>
        <ListWrapper>
          <List>
            {content.test_list.map(({ title, description }, index) => (
              <Item key={title}>
                <ItemTop>
                  <ItemIndex>{index + 1}.</ItemIndex>
                  <ItemTitle>{title}</ItemTitle>
                </ItemTop>
                <ItemDescription>{description}</ItemDescription>
              </Item>
            ))}
          </List>
        </ListWrapper>
      </UI.Container>
    </Section>
  )
}

export default SeriousGamesIndexTest
